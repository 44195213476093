import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { graphql, Link } from "gatsby"
import React from "react"

import jaLocale from "@fullcalendar/core/locales/ja"
import dayGridPlugin from "@fullcalendar/daygrid"
import FullCalendar from "@fullcalendar/react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import "../feature/top/assets/css/index-global.scss"
import * as styles from "../feature/top/assets/css/index.module.scss"
import topImage from "../feature/top/assets/images/gateau-mania.jpeg"
import topImage2 from "../feature/top/assets/images/top2.jpg"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.tz.setDefault("Asia/Tokyo")

const IndexPage = ({ data }) => {
  const events = Array.from(
    new Set(
      data.allMicrocmsBusinessDays.edges
        .map(edge => Object.values(edge.node))
        .flat()
        .filter(Boolean)
    )
  ).map(date => ({
    start: dayjs(date).tz().format("YYYY-MM-DD"),
    classNames: ["business-day"],
  }))
  const notices = data.allMicrocmsNotice.edges
    .filter(
      notice =>
        (!notice.node.startDate ||
          dayjs()
            .startOf("date")
            .isSameOrAfter(dayjs(notice.node.startDate))) &&
        (!notice.node.endDate ||
          dayjs().startOf("date").isSameOrBefore(dayjs(notice.node.endDate)))
    )
    .map(notice => ({
      title: notice.node.title,
      image: notice.node.image?.url,
      content: notice.node.content?.trim(),
      id: notice.node.id.split("-")[0],
    }))
  const renderDayCell = e => {
    const { dayNumberText } = e
    return dayNumberText.replace("日", "")
  }
  return (
    <Layout>
      <Seo title="Home|gateau mania" />
      <div>
        <div className={styles.topImage}>
          <img src={topImage} alt=""></img>
        </div>
        <div className={styles.topMessage}>
          <p className={styles.main}>
            花小金井にあるお家の一角に
            <br />
            木曜日だけオープンする
            <br />
            焼き菓子のお店
          </p>
          <p>
            お菓子マニアの店主が惚れ込んだ、
            <br />
            身体にも地球にもやさしくておいしい材料で作るお菓子たちがお出迎えします◎
          </p>
          <p>
            クッキーおひとつから、
            <br />
            お気軽にお立ち寄りください
          </p>
          <p>
            オーガニックな量り売りと
            <br />
            人気ハンドメイド作家さん達の
            <br />
            作品もあります
          </p>
        </div>
        <div className={styles.notice}>
          <h2>お知らせ</h2>
          <ul>
            {!notices.length && <div>現在お知らせはありません。</div>}
            {notices.map((notice, index) => {
              if (notice.image || notice.content) {
                return (
                  <li key={index}>
                    <Link to={`/notice/${notice.id}`}>{notice.title}</Link>
                  </li>
                )
              }
              return (
                <li key={index}>
                  <span>{notice.title}</span>
                </li>
              )
            })}
          </ul>
        </div>
        <div className={styles.topImage}>
          <img src={topImage2} alt=""></img>
        </div>
        <div className={styles.businessDayCalendar}>
          <h2>営業日カレンダー</h2>
          <div className={styles.calendar}>
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              locales={[jaLocale]}
              locale="ja"
              dayCellContent={renderDayCell}
              contentHeight="290px"
              events={events}
              eventBackgroundColor={"#ffcc99"}
              eventBorderColor={"#ffbf7f"}
            />
            <div className={styles.hint}>営業日</div>
          </div>
        </div>
        <div className={styles.shop}>
          <h2>店舗情報</h2>
          <div>焼き菓子屋</div>
          <div className={styles.shopName}>gateau mania</div>
          <div>毎週木曜日</div>
          <div>13:00-19:00まで</div>
          <div className={styles.lunchBreak}>(16:30-17:00休憩)</div>
          <div>〒187-0011</div>
          <div>東京都小平市鈴木町2-250-2</div>
          <Link className={styles.accessLink} to="/access">
            アクセス
          </Link>
        </div>
        <div className={styles.links}>
          {/* <a
            href="https://twitter.com/gateau_mania?t=0ZoUxalI5TLD5LJL8H-VoQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="bi bi-twitter"></i>
          </a> */}
          <a
            href="https://www.instagram.com/gateau_mania/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-instagram"></i>
          </a>
          <a
            href="mailto:gateau.mania.thu@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="bi bi-envelope"></i>
          </a>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Home" />

export default IndexPage

export const query = graphql`
  query {
    allMicrocmsBusinessDays {
      edges {
        node {
          date1
          date2
          date3
          date4
          date5
        }
      }
    }
    allMicrocmsNotice {
      edges {
        node {
          id
          title
          image {
            url
          }
          content
          startDate
          endDate
        }
      }
    }
  }
`
